/**
 * API
 */
export const SURVEY_URL = `/question`

/**
 * Dispatch
 */
export const SET_SECTION = 'SET_SECTION' // 섹션 설정
export const SET_SLIDE = 'SET_SLIDE' // 슬라이드 설정
export const SET_PERSONAL_INFO = 'SET_PERSONAL_INFO' // 개인 정보 설정
export const SET_CURRENT_QUIZ = 'SET_CURRENT_QUIZ' // 퀴즈 설정
export const ADD_ANSWER = 'ADD_ANSWER' // 답변 등록
export const RESET_ALL = 'RESET_ALL' // 모든 데이터 초기화
export const SET_CONFIRM_HOME = 'SET_CONFIRM_HOME' // 홈 버튼 클릭
export const SET_CONFIRM_SUBMIT = 'SET_CONFIRM_SUBMIT' // 제출 버튼 클릭
export const ADD_AUDIO = 'ADD_AUDIO' // Audio queue 등록
export const TOTAL_SCORE_STORE = 'TOTAL_SCORE_STORE' // 전체 점수 저장

/**
 * Cookie Key
 * @type {string}
 */
export const PERSONAL_INFO_KEY = 'hy_hdc_per' // 개인 정보 키
export const SECTION_KEY = 'hy_hdc_sec' // 섹션 키
export const SLIDE_KEY = 'hy_hdc_sld' // 슬라이드 키
export const QUIZ_ANSWERS_KEY = 'hy_hdc_qza' // 퀴즈 답변 키
