import React, { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Lottie from 'react-lottie'
import qs from 'qs'
import lotties from '../assets/lotties'
import {
  answerQuiz,
  getScore,
  addAudio,
  setScore,
  storeSurvey,
} from '../redux/actions/data'
import { toggleConfirmSubmit } from '../redux/actions/layout'
import ConfirmSubmit from './confirmSubmit'
import nativeInterface from '../modules/NativeInterface'

// function useQuery() {
//   const { search } = useLocation()

//   return React.useMemo(() => new URLSearchParams(search), [search])
// }

const QuizItem = (props) => {
  const dispatch = useDispatch()
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const startTime = Date.now()

  // State
  const [busy, setBusy] = useState(false)
  const [onLevel, setOnLevel] = useState(false)
  const [answer, setAnswer] = useState('')
  const [answerList, setAnswerList] = useState([])
  const [level, setLevel] = useState(null)
  const [mainTime, setMainTime] = useState(0)
  const [subTime, setSubTime] = useState(0)

  // State (Redux)
  const { dataState } = useSelector(
    (state) => ({
      dataState: state.data,
    }),
    shallowEqual
  )
  const { quiz, personalInfo, answers } = dataState

  /**
   * 검사지 답변 선택
   * @param answerType
   */
  const selectAnswer = (answerType) => {
    setAnswer(answerType)
    // setOnLevel(true)
    setLevel(null)
    setMainTime(Math.floor((Date.now() - startTime) / 1000))

    const copiedAnswerList = JSON.parse(
      JSON.stringify(props.quiz.questionList[0].answerList)
    )

    setAnswerList(
      answerType === 'y'
        ? copiedAnswerList.splice(0, 3)
        : copiedAnswerList.splice(3)
    )
  }

  /**
   * 세부 질문 선택
   * @param levelType
   */
  const selectLevel = (levelType) => {
    setLevel(levelType.order - 1)
    setSubTime(Math.floor((Date.now() - startTime) / 1000))
    const id = props.idx + 1
    const answerYesOrNo = props.quiz.questionList[0].reverse
      ? answer === 'y'
      : answer === 'n'
    const fileName = `Q${id}_${answerYesOrNo ? 'Y' : 'N'}${
      answerList.indexOf(levelType) + 1
    }.wav`

    // Play audio
    const audio = new Audio(`/audios/levels/${id}/${fileName}`)
    audio.play()
    dispatch(addAudio(audio))
  }

  /**
   * 이전 단계 이동
   */
  const goPrev = () => {
    if (onLevel) {
      setOnLevel(false)
    } else if (props.idx === 0) {
      props.sectionSliderRef.current.slickPrev()
    } else {
      props.quizSliderRef.current.slickPrev()
    }
  }

  /**
   * 다음 단계 이동
   */
  const goNext = () => {
    if (onLevel && level !== null) {
      goNextQuiz()
    } else if (answer) {
      setOnLevel(true)
    }
  }

  /**
   * 세부 선택 & 다음 퀴즈 이동
   */
  const goNextQuiz = () => {
    if (busy) return
    setBusy(true)

    const answerYesOrNo = answer === 'n'

    const answerObj = {
      mainNumber: props.idx,
      mainSelect: answerYesOrNo ? 1 : 0,
      level: `${(Number(props.idx) + 1) % 10}-${
        answerYesOrNo ? '1' : '0'
      }-${level}`,
      subSelect: Number(level),
      mainTime,
      subTime,
    }

    dispatch(answerQuiz(answerObj))
    // setOnLevel(false)
    // setAnswer('')

    // 퀴즈 종료
    if (props.idx === quiz.length - 1) {
      dispatch(toggleConfirmSubmit(true))
      setBusy(false)
    } else {
      // Play audio
      const audio = new Audio(`/audios/questions/${props.idx + 2}.wav`)
      audio.play()
      dispatch(addAudio(audio))

      props.quizSliderRef.current.slickNext()
      setBusy(false)
    }
  }

  /**
   * 설문 제출 & 종료
   */
  const finishQuiz = async () => {
    if (busy) return
    setBusy(true)

    // const userAnswerList = []
    // answers.forEach((a, aIdx) => {
    //   userAnswerList.push({
    //     order: a.idx + 1,
    //     questionSn: quiz[aIdx].id,
    //     answerSn: a.answerSn,
    //   })
    // })

    const surveyObj = {
      ...query,
      score: answers.map((a) => a.mainSelect).reduce((a, b) => a + b, 0),
      severityCode: '02',
      severityName: '심각',
    }

    Object.keys(surveyObj).forEach((key) => {
      if (!['saveApi', 'severityCode', 'severityName'].includes(key))
        surveyObj[key] = Number(surveyObj[key])
    })

    if (surveyObj.score < 5) {
      surveyObj.severityCode = '00'
      surveyObj.severityName = '가벼운'
    } else if (surveyObj.score < 8) {
      surveyObj.severityCode = '01'
      surveyObj.severityName = '보통'
    }

    try {
      const result = await getScore({
        // userToken: surveyObj,
        // name: personalInfo.name,
        // birth: personalInfo.dob.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
        gender: personalInfo.gender,
        submit: answers,
      })

      dispatch(setScore(result.data.query.score))

      if (navigator.userAgent.includes('Morpheus')) {
        const submitCbData = {
          status: 'success',
          data: {
            score: surveyObj.score,
            main: answers.map((a) => a.mainSelect).join(','),
            sub: answers.map((a) => a.subSelect).join(','),
          },
        }
        window.parent.postMessage(submitCbData, '*')
      }

      // if (surveyObj.saveApi)
      //   await sendSurvey({ ...surveyObj, score: surveyObj.score * 10 })

      setOnLevel(false)
      setAnswer('')
      props.sectionSliderRef.current.slickNext()
      props.quizSliderRef.current.slickGoTo(0)
      dispatch(toggleConfirmSubmit(false))
      nativeInterface.exitApp()
    } catch (e) {
      // console.log(e)
    } finally {
      setBusy(false)
    }
  }

  return (
    <div
      className={`page bg-yellow quiz overflow-hidden ${
        onLevel ? 'on-level' : ''
      }`}
    >
      <div className="quiz-question">
        <div className="quiz-emoji">
          <Lottie
            isClickToPauseDisabled
            options={{
              loop: true,
              autoplay: true,
              animationData: lotties[props.idx],
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            width="100%"
            height="100%"
          />
        </div>
        <div className="quiz-title flex items-start mb-5">
          <span className="no">{props.quiz.questionList[0].no}&nbsp;</span>
          <p
            className="text-2xl font-semibold line-clamp-2"
            dangerouslySetInnerHTML={{
              __html: props.quiz.questionList[0].languageMap.KO.replace(
                /\n/g,
                '<br />'
              ),
            }}
          />
          <div className="progress">
            <div
              className="progress-per"
              style={{ width: `${((props.idx + 1) / quiz.length) * 100}%` }}
            />
          </div>
        </div>
        <div className="quiz-content">
          <div className="w-full relative">
            <ul className="quiz-answer yes-or-no flex justify-center">
              <li
                className={`flex-grow text-center ${
                  props.quiz.questionList[0].reverse ? 'yes' : 'no'
                } ${
                  answer === (props.quiz.questionList[0].reverse ? 'y' : 'n')
                    ? 'active'
                    : ''
                }`}
                onClick={() =>
                  selectAnswer(props.quiz.questionList[0].reverse ? 'y' : 'n')
                }
              >
                <div className="mb-1.5">
                  <i />
                </div>
                <div className="px-1.5 checked">그렇다</div>
              </li>
              <li
                className={`flex-grow text-center ${
                  props.quiz.questionList[0].reverse ? 'no' : 'yes'
                } ${
                  answer === (props.quiz.questionList[0].reverse ? 'n' : 'y')
                    ? 'active'
                    : ''
                }`}
                onClick={() =>
                  selectAnswer(props.quiz.questionList[0].reverse ? 'n' : 'y')
                }
              >
                <div className="mb-1.5">
                  <i />
                </div>
                <div className="px-1.5 checked">아니다</div>
              </li>
            </ul>
          </div>
          <div className="quiz-level">
            <div className="quiz-level-content">
              <ul className="quiz-level-list space-y-4">
                {answerList.map((a, aIdx) => (
                  <li
                    key={a.id}
                    className={`quiz-level-item quiz-level-item-${
                      props.idx + 1
                    }-${a.order} ${a.order - 1 === level ? 'current' : ''}`}
                    onClick={() => selectLevel(a)}
                  >
                    <div className="icon-wrap">
                      <div className="icon" />
                    </div>
                    <div className="text">{a.languageMap.KO}</div>
                    {a.order - 1 === level ? (
                      <div className="icon-bg">
                        <Lottie
                          isClickToPauseDisabled
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData: lotties[`bg_${a.order}`],
                            rendererSettings: {
                              preserveAspectRatio: 'xMidYMid slice',
                            },
                          }}
                          height="100%"
                          width="100%"
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <footer>
          <div className="footer-content">
            <ul>
              <li className="active">
                <button
                  type="button"
                  className="footer-link"
                  onClick={() => goPrev()}
                >
                  이전
                </button>
              </li>
              <li
                className={
                  (!onLevel && !answer) || (onLevel && level === null)
                    ? ''
                    : 'active'
                }
              >
                <button
                  type="button"
                  className="footer-link"
                  onClick={() => goNext()}
                >
                  다음
                </button>
              </li>
            </ul>
          </div>
        </footer>
      </div>
      {props.idx === quiz.length - 1 ? (
        <ConfirmSubmit onFinsh={finishQuiz} />
      ) : (
        ''
      )}
    </div>
  )
}

export default QuizItem
