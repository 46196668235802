import Cookies from 'js-cookie'
import * as actionTypes from '../../actionTypes'

const initialState = {
  section: 0,
  slide: 0,
  confirmHome: false,
  confirmSubmit: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // 섹션 설정
    case actionTypes.SET_SECTION:
      /* Cookies.set(actionTypes.SECTION_KEY, action.payload, {
        expires: 86400,
        sameSite: 'lax',
      }) */

      return {
        ...state,
        section: Number(action.payload),
      }
    // 슬라이드 설정
    case actionTypes.SET_SLIDE:
      /* Cookies.set(actionTypes.SLIDE_KEY, action.payload, {
        expires: 86400,
        sameSite: 'lax',
      }) */

      return {
        ...state,
        slide: Number(action.payload),
      }
    // 홈 화면 가기
    case actionTypes.SET_CONFIRM_HOME:
      return {
        ...state,
        confirmHome: action.payload,
      }
    // 제출하기
    case actionTypes.SET_CONFIRM_SUBMIT:
      return {
        ...state,
        confirmSubmit: action.payload,
      }
    default:
      return state
  }
}

export default reducer
