import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import * as axios from 'axios'
import Cookies from 'js-cookie'
import Section1 from './sections/section1'
import Section2 from './sections/section2'
import Section3 from './sections/section3'
import ConfirmHome from './components/confirmHome'
import * as actionTypes from './redux/actionTypes'
import { setPersonalInfo } from './redux/actions/data'
import { setSection, toggleConfirmHome } from './redux/actions/layout'

function App() {
  const dispatch = useDispatch()

  // State (Redux)
  const { layoutState } = useSelector(
    (state) => ({
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const { slide } = layoutState

  useEffect(() => {
    // 내 프로필 가져오기
    if (Cookies.get(actionTypes.PERSONAL_INFO_KEY)) {
      dispatch(
        setPersonalInfo(JSON.parse(Cookies.get(actionTypes.PERSONAL_INFO_KEY)))
      )
    }

    setDeviceHeight()

    window.addEventListener('resize', () => {
      setDeviceHeight()
    })
  }, [])

  const setDeviceHeight = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  return (
    <div className="App">
      <Section1 />
    </div>
  )
}

export default App
