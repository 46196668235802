import Cookies from 'js-cookie'
import * as actionTypes from '../../actionTypes'
import quiz from '../../../data/quiz.json'
import { PERSONAL_INFO_KEY, QUIZ_ANSWERS_KEY } from '../../actionTypes'

const initialState = {
  personalInfo: {
    name: '',
    dob: '',
    gender: '',
  },
  quiz: quiz.data.groupList,
  currentQuizIndex: 0,
  currentQuiz: quiz.data.groupList[0],
  answers: Cookies.get(actionTypes.QUIZ_ANSWERS_KEY)
    ? JSON.parse(Cookies.get(actionTypes.QUIZ_ANSWERS_KEY))
    : [],
  score: 0,
  audios: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // 개인 정보 설정
    case actionTypes.SET_PERSONAL_INFO:
      /* Cookies.set(
        actionTypes.PERSONAL_INFO_KEY,
        JSON.stringify(action.payload),
        {
          expires: 86400,
          sameSite: 'lax',
        }
      ) */

      return {
        ...state,
        personalInfo: action.payload,
      }
    // 현재 퀴즈 설정
    case actionTypes.SET_CURRENT_QUIZ:
      return {
        ...state,
        currentQuizIndex: action.payload,
        currentQuiz: quiz[action.payload],
      }
    // 답변 등록
    case actionTypes.ADD_ANSWER:
      const targetIdx = action.payload.idx
      const updatedAnswers = state.answers

      // Update
      if (updatedAnswers[targetIdx]) {
        updatedAnswers[targetIdx] = action.payload
      } else {
        updatedAnswers.push(action.payload)
      }

      /* Cookies.set(
        actionTypes.QUIZ_ANSWERS_KEY,
        JSON.stringify(updatedAnswers),
        {
          expires: 86400,
          sameSite: 'lax',
        }
      ) */

      return {
        ...state,
        answers: updatedAnswers,
      }
    case actionTypes.TOTAL_SCORE_STORE:
      return {
        ...state,
        score: action.payload,
      }

    // 모든 데이터 초기화
    case actionTypes.RESET_ALL:
      Cookies.remove(actionTypes.PERSONAL_INFO_KEY, { path: '/' })
      Cookies.remove(actionTypes.SECTION_KEY, { path: '/' })
      Cookies.remove(actionTypes.SLIDE_KEY, { path: '/' })
      Cookies.remove(actionTypes.QUIZ_ANSWERS_KEY, { path: '/' })

      return {
        ...state,
        personalInfo: {
          name: '',
          dob: '',
          gender: '',
        },
        answers: [],
      }
    // Audio queue 등록
    case actionTypes.ADD_AUDIO:
      // 기존 오디오 멈춤
      if (state.audios) {
        state.audios.forEach((fa) => {
          fa.pause()
        })
      }

      return {
        ...state,
        audios: [action.payload],
      }
    default:
      return state
  }
}

export default reducer
