import React, { useRef, useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Slider from 'react-slick'
import QuizItem from '../../components/quizItem'
import { configSlickQuiz } from '../../configs/slickQuiz'

const Quiz = (props) => {
  // Ref
  const quizSliderRef = useRef(null)

  // State (Redux)
  const { dataState } = useSelector(
    (state) => ({
      dataState: state.data,
    }),
    shallowEqual
  )
  const { quiz, answers } = dataState

  // Effect
  useEffect(() => {
    if (answers && answers.length) {
      quizSliderRef.current.slickGoTo(answers.length)
    }
  }, [answers])

  return (
    <div className="relative">
      <Slider ref={quizSliderRef} {...configSlickQuiz}>
        {quiz.map((q, qIdx) => (
          <QuizItem
            sectionSliderRef={props.sectionSliderRef}
            quizSliderRef={quizSliderRef}
            quiz={q}
            key={q.id}
            id={q.id}
            idx={qIdx}
          />
        ))}
      </Slider>
    </div>
  )
}

export default Quiz
