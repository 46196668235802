import React, { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { toggleConfirmSubmit } from '../redux/actions/layout'

const ConfirmSubmit = (props) => {
  const dispatch = useDispatch()

  // State

  // State (Redux)
  const { dataState, layoutState } = useSelector(
    (state) => ({
      dataState: state.data,
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const { personalInfo, quiz, answers } = dataState
  const { slide, confirmSubmit } = layoutState

  /**
   * 제출하기
   */
  const goSubmit = () => {
    props.onFinsh()
    /* dispatch(toggleConfirmSubmit(false))
    props.sectionSliderRef.current.slickGoTo(0) */
  }

  return (
    <div className={`confirm ${confirmSubmit ? 'show' : ''}`}>
      <div className="confirm-dialog">
        <button
          type="button"
          onClick={() => dispatch(toggleConfirmSubmit(false))}
          className="btn btn-close absolute top-4 right-4"
        >
          닫기
        </button>
        <div className="confirm-body">
          <h2 className="text-2xl font-semibold mb-2.5">
            자가 진단 설문 내용을
            <br />
            제출하시겠습니까?
          </h2>
          <p className="text-sm" />
        </div>
        <div className="confirm-footer">
          <button
            type="button"
            className="btn btn-confirm"
            onClick={() => dispatch(toggleConfirmSubmit(false))}
          >
            취소
          </button>
          <button
            type="button"
            className="btn btn-confirm primary"
            onClick={() => goSubmit()}
          >
            제출
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmSubmit
