import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { rootReducer } from '../reducers/rootReducer'

// create your reducer
const reducer = (state = {}, action) => {
  return rootReducer(state, action)
}

// create a makeStore function
const store = createStore(reducer, applyMiddleware(thunk))

export default store
