import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Cookies from 'js-cookie'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import * as actionTypes from '../../redux/actionTypes'
import { setPersonalInfo } from '../../redux/actions/data'

const personalInfoCookie = Cookies.get(actionTypes.PERSONAL_INFO_KEY)
  ? JSON.parse(Cookies.get(actionTypes.PERSONAL_INFO_KEY))
  : {}

const initialValues = {
  name: personalInfoCookie.name ? personalInfoCookie.name : '',
  dob: personalInfoCookie.dob ? personalInfoCookie.dob : '',
}

const NameDob = (props) => {
  const dispatch = useDispatch()

  // State
  const [busy, setBusy] = useState(false)

  // State (Redux)
  const { dataState } = useSelector(
    (state) => ({ dataState: state.data }),
    shallowEqual
  )
  const { personalInfo } = dataState

  // Validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('이름을 입력하세요'),
    dob: Yup.string()
      .required('올바른 형식으로 입력해주세요 (yyyymmdd)')
      .matches(/^\d{4}\d{2}\d{2}$/, '올바른 형식으로 입력해주세요 (yyyymmdd)'),
  })

  // Formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setBusy(true)

      // 개인정보 저장
      const newPersonalInfo = {
        name: values.name,
        dob: values.dob,
        gender: personalInfo.gender,
      }

      dispatch(setPersonalInfo(newPersonalInfo))
      props.sectionSliderRef.current.slickNext()
      setBusy(false)
    },
  })

  return (
    <div className="page bg-yellow">
      <div style={{ marginBottom: '4.8125rem' }}>
        <p className="text-2xl font-semibold mb-2.5">
          이름과 생년월일을
          <br />
          입력해주세요
        </p>
        <p className="text-sm">본 진단의 응답내용은 의료진만 알 수 있습니다</p>
      </div>
      <form onSubmit={formik.handleSubmit} method="POST">
        <div className="mb-6">
          <label htmlFor="name">
            <input
              type="text"
              placeholder="이름"
              id="name"
              name="name"
              className="input-text"
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </label>
        </div>
        <div>
          <label htmlFor="dob">
            <input
              type="tel"
              placeholder="생년월일"
              id="dob"
              name="dob"
              className={`input-text ${
                formik.touched.dob && formik.errors.dob ? 'error' : ''
              }`}
              onChange={formik.handleChange}
              value={formik.values.dob}
              maxLength={8}
            />
          </label>
          {formik.touched.dob && formik.errors.dob ? (
            <p className="my-2 text-sm color-red">{formik.errors.dob}</p>
          ) : null}
        </div>
        <footer>
          <div className="footer-content">
            <ul>
              <li />
              <li
                className={`${
                  !formik.isValid || !formik.values.name || !formik.values.dob
                    ? ''
                    : 'active'
                }`}
              >
                <button type="submit" className="footer-link" disabled={busy}>
                  다음
                </button>
              </li>
            </ul>
          </div>
        </footer>
      </form>
    </div>
  )
}

export default NameDob
