import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import layout from './layout'
import data from './data'

export const rootReducer = combineReducers({
  layout,
  data,
})

export function* rootSaga() {
  yield all([])
}
