import React, { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import { toggleConfirmHome } from '../redux/actions/layout'
import * as actionTypes from '../redux/actionTypes'
import { resetData } from '../redux/actions/data'

const ConfirmHome = (props) => {
  const dispatch = useDispatch()

  // State

  // State (Redux)
  const { dataState, layoutState } = useSelector(
    (state) => ({
      dataState: state.data,
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const { personalInfo, quiz, answers } = dataState
  const { slide, confirmHome } = layoutState

  /**
   * 홈으로 가기
   * @param isSave
   */
  const goHome = (isSave) => {
    if (isSave) {
      Cookies.set(actionTypes.SLIDE_KEY, slide, {
        expires: 86400,
        sameSite: 'lax',
      })

      Cookies.set(actionTypes.PERSONAL_INFO_KEY, JSON.stringify(personalInfo), {
        expires: 86400,
        sameSite: 'lax',
      })

      Cookies.set(actionTypes.QUIZ_ANSWERS_KEY, JSON.stringify(answers), {
        expires: 86400,
        sameSite: 'lax',
      })
    } else {
      dispatch(resetData())
      location.reload()
    }

    dispatch(toggleConfirmHome(false))
    props.sectionSliderRef.current.slickGoTo(0)
  }

  return (
    <div className={`confirm ${confirmHome ? 'show' : ''}`}>
      <div className="confirm-dialog">
        <button
          type="button"
          onClick={() => dispatch(toggleConfirmHome(false))}
          className="btn btn-close absolute top-4 right-4"
        >
          닫기
        </button>
        <div className="confirm-body">
          <h2 className="text-2xl font-semibold mb-2.5">
            자가 진단 설문 내용을
            <br />
            저장할까요?
          </h2>
          <p className="text-sm">
            저장하지 않고 홈 화면으로 이동할 경우,
            <br />
            설문이 처음부터 다시 진행됩니다.
          </p>
        </div>
        <div className="confirm-footer">
          <button
            type="button"
            className="btn btn-confirm"
            onClick={() => goHome()}
          >
            저장하지 않음
          </button>
          <button
            type="button"
            className="btn btn-confirm primary"
            onClick={() => goHome(true)}
          >
            저장 후 이동
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmHome
