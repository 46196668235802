import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import ImageFaces from '../../assets/images/img-faces@3x.png'
import { setSlide } from '../../redux/actions/layout'

const Start = (props) => {
  const dispatch = useDispatch()

  // State
  const [busy, setBusy] = useState(false)

  /**
   * 퀴즈 시작
   */
  const start = () => {
    setBusy(true)
    props.sectionSliderRef.current.slickNext()

    // Play audio
    const audio = new Audio(`/audios/questions/1.wav`)
    audio.play()
  }

  return (
    <div className="page bg-yellow">
      <div>
        <p className="text-2xl font-semibold mb-2.5">
          시작할 준비가 되었어요.
          <br />
          검사를 시작할까요?
        </p>
        <div className="sub-info text-l mt-10 mb-10 leading-8">
          다음 문항을 잘 읽으시고
          <br />
          지난 1주 동안 당신이 느끼고
          <br />
          행동한 것을 가장 잘 나타낸다고
          <br />
          생각되는 답변을 선택해주세요.
        </div>
        <p className="text-sm">다음 버튼을 눌러 검사를 시작하세요</p>
      </div>
      <div className="mt-20">
        <p className="sub-info text-l leading-8">
          “CESD-10-D는 우울을 측정하기 위해 미국정신보건연구원에서 개발한
          CES-D를 한국판으로 표준화한 단축형 검사입니다.”
        </p>
      </div>
      <footer>
        <div className="footer-content">
          <ul>
            <li className="active">
              <button
                type="button"
                className="footer-link"
                onClick={() => props.sectionSliderRef.current.slickPrev()}
              >
                이전
              </button>
            </li>
            <li className="active">
              <button
                type="submit"
                className="footer-link"
                onClick={() => start()}
              >
                다음
              </button>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  )
}

export default Start
