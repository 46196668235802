class NativeInterface {
  constructor() {
    this.window = window
    this.init()
  }

  init() {
    this.window.testAlert = (msg) => {
      alert(msg)
    }
  }

  sendAppMessage(type, args) {
    if (!this.window.flutter_inappwebview) return

    this.window.flutter_inappwebview
      .callHandler('sendAppMessage', { ...args, type })
      .then(function (result) {
        console.log(result)
      })
  }

  exitApp() {
    if (!this.window.flutter_inappwebview) return

    this.window.flutter_inappwebview
      .callHandler('sendAppMessage', { action: 'exitRequest' })
      .then(function (result) {
        console.log(result)
      })
  }
}

export default new NativeInterface()
