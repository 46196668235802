export default {
  0: require('./1.json'),
  1: require('./2.json'),
  2: require('./3.json'),
  3: require('./4.json'),
  4: require('./5.json'),
  5: require('./6.json'),
  6: require('./7.json'),
  7: require('./8.json'),
  8: require('./9.json'),
  9: require('./10.json'),
  bg_1: require('./bg_1.json'),
  bg_2: require('./bg_2.json'),
  bg_3: require('./bg_3.json'),
  bg_4: require('./bg_4.json'),
  bg_5: require('./bg_5.json'),
  bg_6: require('./bg_6.json'),
  intro: require('./intro.json'),
}
