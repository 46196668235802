import React, { useState, useEffect, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Lottie from 'react-lottie'
import lotties from '../../assets/lotties'

const Intro = (props) => {
  const dispatch = useDispatch()

  // State
  const [busy, setBusy] = useState(true)

  // State (Redux)
  const { layoutState } = useSelector(
    (state) => ({
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const { slide } = layoutState

  // Ref
  const lottieRef = useRef(null)

  // Effect
  useEffect(() => {
    if (slide === 0) {
      setBusy(true)

      setTimeout(() => {
        if (lottieRef.current) lottieRef.current.play()
      }, 100)
    }
  }, [slide])

  useEffect(() => {
    if (!busy) {
      props.sectionSliderRef.current.slickNext()
    }
  }, [busy])

  return (
    <div className="page intro bg-yellow p-0">
      {slide === 0 ? (
        <Lottie
          ref={lottieRef}
          isClickToPauseDisabled
          options={{
            loop: false,
            autoplay: false,
            animationData: lotties.intro,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid meet',
            },
          }}
          eventListeners={[
            {
              eventName: 'complete',
              callback: () => {
                setBusy(false)
                // props.sectionSliderRef.current.slickGoTo(1)
              },
            },
          ]}
          width="100%"
          height="100%"
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default Intro
