import * as actionTypes from '../../actionTypes'

/**
 * 현재 퀴즈 설정
 * @param sectionNo
 * @returns {(function(*): void)|*}
 */
export const setSection = (sectionNo) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_SECTION,
    payload: sectionNo,
  })
}

/**
 * 현재 퀴즈 설정
 * @param slideNo
 * @returns {(function(*): void)|*}
 */
export const setSlide = (slideNo) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_SLIDE,
    payload: slideNo,
  })
}

/**
 * 홈 화면 가기
 * @param isOpen
 * @returns {(function(*): void)|*}
 */
export const toggleConfirmHome = (isOpen) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_CONFIRM_HOME,
    payload: isOpen,
  })
}

/**
 * 홈 화면 가기
 * @param isOpen
 * @returns {(function(*): void)|*}
 */
export const toggleConfirmSubmit = (isOpen) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_CONFIRM_SUBMIT,
    payload: isOpen,
  })
}
