export const configSlickQuiz = {
  dots: false,
  lazyLoad: true,
  arrows: false,
  infinite: false,
  swipe: false,
  speed: 0,
  slidesToShow: 1,
  slidesToScroll: 1,
  afterChange(current) {
    /* if (
      document.getElementById('progress') &&
      document.getElementById('section2').classList.contains('show')
    ) {
      document.getElementById('progress').style.width = `${
        (current / 10) * 100
      }%`
    } */
  },
}
